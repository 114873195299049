// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("E:\\Work\\GitHub\\jlemus1234.github.io\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("E:\\Work\\GitHub\\jlemus1234.github.io\\src\\pages\\AboutMe.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-bisexual-resource-center-js": () => import("E:\\Work\\GitHub\\jlemus1234.github.io\\src\\pages\\BisexualResourceCenter.js" /* webpackChunkName: "component---src-pages-bisexual-resource-center-js" */),
  "component---src-pages-graduation-js": () => import("E:\\Work\\GitHub\\jlemus1234.github.io\\src\\pages\\Graduation.js" /* webpackChunkName: "component---src-pages-graduation-js" */),
  "component---src-pages-hobbies-js": () => import("E:\\Work\\GitHub\\jlemus1234.github.io\\src\\pages\\Hobbies.js" /* webpackChunkName: "component---src-pages-hobbies-js" */),
  "component---src-pages-hub-js": () => import("E:\\Work\\GitHub\\jlemus1234.github.io\\src\\pages\\Hub.js" /* webpackChunkName: "component---src-pages-hub-js" */),
  "component---src-pages-index-js": () => import("E:\\Work\\GitHub\\jlemus1234.github.io\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mc-donalds-js": () => import("E:\\Work\\GitHub\\jlemus1234.github.io\\src\\pages\\McDonalds.js" /* webpackChunkName: "component---src-pages-mc-donalds-js" */),
  "component---src-pages-mixr-js": () => import("E:\\Work\\GitHub\\jlemus1234.github.io\\src\\pages\\MIXR.js" /* webpackChunkName: "component---src-pages-mixr-js" */),
  "component---src-pages-projects-js": () => import("E:\\Work\\GitHub\\jlemus1234.github.io\\src\\pages\\projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-resume-js": () => import("E:\\Work\\GitHub\\jlemus1234.github.io\\src\\pages\\resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-sen-sit-js": () => import("E:\\Work\\GitHub\\jlemus1234.github.io\\src\\pages\\SenSit.js" /* webpackChunkName: "component---src-pages-sen-sit-js" */),
  "component---src-pages-swim-sense-js": () => import("E:\\Work\\GitHub\\jlemus1234.github.io\\src\\pages\\SwimSense.js" /* webpackChunkName: "component---src-pages-swim-sense-js" */),
  "component---src-pages-tongue-interface-js": () => import("E:\\Work\\GitHub\\jlemus1234.github.io\\src\\pages\\TongueInterface.js" /* webpackChunkName: "component---src-pages-tongue-interface-js" */),
  "component---src-pages-writing-js": () => import("E:\\Work\\GitHub\\jlemus1234.github.io\\src\\pages\\writing.js" /* webpackChunkName: "component---src-pages-writing-js" */)
}

